<template>
	<div style="min-height: 300px; max-height: 70vh" class="tw-overflow-scroll">
        <table class="tw-w-full table">
            <thead>
                <tr>
                    <th
                        class="tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2 tw-w-8"
                    >
                        No
                    </th>
                    <th
                        class="tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2 tw-w-32"
                    >
                        Driver Code
                    </th>
                    <th
                        class="tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2"
                    >
                        Driver Name
                    </th>
                    <th
                        class="tw-text-right tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2"
                    >
                        T.Packages
                    </th>
                    <th
                        class="tw-text-right tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2"
                    >
                        T.Success
                    </th>
                    <th
                        class="tw-text-right tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2"
                    >
                        T.Pending
                    </th>
                    <th
                        class="tw-text-right tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2"
                    >
                        T.Pickup
                    </th>
                    <th
                        class="tw-text-right tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2"
                    >
                        T.Return
                    </th>
                    <th
                        class="tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2"
                    >
                        Delivery Fee
                    </th>
                    <th
                        class="tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2"
                    >
                        Pickup Fee
                    </th>
                    <th
                        class="tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2"
                    >
                        Total Fee
                    </th>
								</tr>
						</thead>
						<tbody>
							<tr
								v-for="(r, index) in resources"
								:key="index"
							>
								<td>{{ index + 1 }}</td>
								<td class="tw-border-b tw-text-left tw-px-3 tw-py-1">{{ r.driver_code }}</td>
								<td class="tw-border-b tw-text-left tw-px-3 tw-py-1">{{ r.driver_name }}</td>
								<td class="tw-border-b tw-px-3 tw-py-1 tw-text-right">{{ r.total_package }}</td>
								<td class="tw-border-b tw-px-3 tw-py-1 tw-text-right">{{ r.total_success }}</td>
								<td class="tw-border-b tw-px-3 tw-py-1 tw-text-right">{{ r.total_pending }}</td>
								<td class="tw-border-b tw-px-3 tw-py-1 tw-text-right">{{ r.total_pickup }}</td>
								<td class="tw-border-b tw-px-3 tw-py-1 tw-text-right">{{ r.total_return }}</td>
								<td class="tw-border-b tw-text-left tw-px-3 tw-py-1">
									<ts-accounting-format
											:digit="2"
											:value="r.delivery_fee"
									></ts-accounting-format>
								</td>
								<td class="tw-border-b tw-text-left tw-px-3 tw-py-1">
									<ts-accounting-format
											:digit="2"
											:value="r.pickup_fee"
									></ts-accounting-format>
								</td>
								<td class="tw-border-b tw-text-left tw-px-3 tw-py-1">
									<ts-accounting-format
											:digit="2"
											:value="r.total_fee"
									></ts-accounting-format>
								</td>
							</tr>
						</tbody>
						<tfoot>
							<tr>
								<td colspan="3">
									<strong>Total:</strong>
								</td>
								<td class="tw-text-right tw-text-blue-500">
										<strong>{{ $formatNumber(sumByColumn('total_package')) }}</strong>
								</td>
								<td class="tw-text-right tw-text-blue-500">
										<strong>{{ $formatNumber(sumByColumn('total_success')) }}</strong>
								</td>
								<td class="tw-text-right tw-text-blue-500">
										<strong>{{ $formatNumber(sumByColumn('total_pending')) }}</strong>
								</td>
								<td class="tw-text-right tw-text-blue-500">
										<strong>{{ $formatNumber(sumByColumn('total_pickup')) }}</strong>
								</td>
								<td class="tw-text-right tw-text-blue-500">
										<strong>{{ $formatNumber(sumByColumn('total_return')) }}</strong>
								</td>
								<td>
									<ts-accounting-format
											class="tw-font-extrabold tw-text-blue-500"
											:digit="2"
											:value="sumByColumn('delivery_fee')"
									></ts-accounting-format>
								</td>
								<td>
									<ts-accounting-format
											class="tw-font-extrabold tw-text-blue-500"
											:digit="2"
											:value="sumByColumn('pickup_fee')"
									></ts-accounting-format>
								</td>
								<td>
									<ts-accounting-format
											class="tw-font-extrabold tw-text-blue-500"
											:digit="2"
											:value="sumByColumn('total_fee')"
									></ts-accounting-format>
								</td>
							</tr>
						</tfoot>
				</table>
	</div>
</template>

<script>
import { sumBy } from 'lodash';
export default {
	name: 'driverTransactionSummaryComponent',
	props:['resources'],
	computed: {
		sumByColumn: vm => column => {
			return sumBy(vm.resources, column);
		},
	}
}
</script>

<style lang="css" scoped>
::-webkit-scrollbar {
    width: 8px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: rgb(161, 3, 3);
}
.table > thead > tr > th {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 999;
    background-color: rgba(59, 130, 246);
}
.table > tbody > tr > td {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    border: none !important;
}

.table > tfoot {
  position: sticky;
  bottom: 0;
  z-index: 1;
  background-color: white;
}
</style>